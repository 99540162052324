@import "variables";

.signIn {
  button {
    margin: 10px 0 40px;
  }
  a {
    margin-bottom: 20px;
  }
}
