@import "variables";

.button {
  background-color: $pepdirect-blue;
  border-color: $pepdirect-blue;
  &:hover {
    background-color: $pepdirect-blue;
    border-color: $pepdirect-blue;
    opacity: 0.9;
  }
}
