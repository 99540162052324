@import "variables";

.text {
  width: 100%;
  margin-top: $margin-base;
  font-size: 12px;
  color: #888;
  a {
    color: $primary-color;
    text-decoration: underline;
  }
}
