@import "variables";
@import "colors";

.pageCard {
  background-color: #f2f2f2;
  min-height: 800px;
  .container {
    margin: 0 auto;
    padding: 50px 0;
    max-width: 450px;
    h1.header {
      font-size: 24px;
      font-weight: 500;
    }
    .box {
      background-color: #fff;
      border: 1px solid #d9d9d9;

      h2.headerSmall {
        padding: 25px;
        font-size: 16px;
        font-weight: 700;
        border-bottom: 1px solid #d9d9d9;
        text-align: left;
      }
      .boxInner {
        padding: 25px;
        display: flex;
        flex-direction: column;
        text-align: center;
      }
    }
  }
}
